<template>
  <div class="loyalty-advantage">
    <header class="page-header">
      <PageTitle v-if="loyaltyAdvantage" :item="loyaltyAdvantage"/>
    </header>

    <div class="main-content">
      <div class="pane table-pane">
        <formulate-form v-if="loyaltyAdvantage && !isLoading" v-model="loyaltyAdvantage" @submit="submit">

          <formulate-input :options="starsList" label="Sterren" name="stars" type="select" validation="required"></formulate-input>
          <formulate-input label="Hoeveelheid" name="amount" type="number" validation="required|max:100|min:0"></formulate-input>

          <formulate-input #default="{ index }" groupRepeatable-class="translations-group formulate-input-group-repeatable" name="translations" type="group">
            <h3>Vertaling: {{ activeLocalesBack[getLangCode(loyaltyAdvantage, index)] }}</h3>
            <button v-if="getLangCode(loyaltyAdvantage, index) !== 'nl'" class="btn btn-remove-translation" type="button" @click="removeTrans(loyaltyAdvantage, index)">Remove</button>

            <formulate-input name="lang_code" type="hidden"></formulate-input>
            <formulate-input label="Titel" name="title" type="text" validation="required"></formulate-input>
            <formulate-input label="Body" name="body" type="tiptap"/>
          </formulate-input>

          <div v-if="Object.keys(activeLocalesBack).length > loyaltyAdvantage.translations.length">
            <h4>{{ $t('Vertaling toevoegen') }}</h4>

            <div class="language-buttons">
              <button v-if="!hasTrans(loyaltyAdvantage,'fr')" class="btn" type="button" @click="addTrans(loyaltyAdvantage, 'fr')">{{ activeLocalesBack['fr'] }}</button>
              <button v-if="!hasTrans(loyaltyAdvantage,'en')" class="btn" type="button" @click="addTrans(loyaltyAdvantage, 'en')">{{ activeLocalesBack['en'] }}</button>
              <button v-if="!hasTrans(loyaltyAdvantage,'sv')" class="btn" type="button" @click="addTrans(loyaltyAdvantage, 'sv')">{{ activeLocalesBack['sv'] }}</button>
            </div>
          </div>

          <formulate-input :label="isLoading ? $t('Even geduld...') : $t('Opslaan')" input-class="btn" type="submit"/>
        </formulate-form>
      </div>
    </div>
  </div>
</template>

<script>
import { ApiService } from '@/services/admin/api.service'
import { notification } from '@/notifications'
import PageTitle from '@/components/admin/PageTitle'

export default {
  components: { PageTitle },
  data () {
    return {
      isLoading: true,
      loyaltyAdvantageId: this.$route.params.id,
      loyaltyAdvantage: null,
      starsList: {
        0: 'Algemeen',
        1: 1,
        2: 2,
        3: 3,
        4: 4
      }
    }
  },
  async mounted () {
    if (this.loyaltyAdvantageId) {
      await this.fetchLoyaltyAdvantage(this.loyaltyAdvantageId)
    }

    if (!this.loyaltyAdvantage) {
      this.loyaltyAdvantage = {
        translations: [
          {
            lang_code: this.$i18n.locale
          }
        ]
      }
    }

    this.isLoading = false
  },
  methods: {
    uploadIconComplete: async function () {
      if (this.loyaltyAdvantage?.files?.results?.[0] !== undefined) {
        this.loyaltyAdvantage.image_file_id = this.loyaltyAdvantage.files.results[0][0]
        this.uploading = false
      }
    },
    fetchLoyaltyAdvantage: async function (id) {
      this.loyaltyAdvantage = (await ApiService.fetchLoyaltyAdvantage(id)).data
    },
    async submit (formData) {
      this.isLoading = true

      const res = await ApiService.saveLoyaltyAdvantage(formData)

      if (res.status === 200) {
        notification(this.$t('Loyalty advantage succesvol aangemaakt!'))
        await this.$router.push({ name: 'admin.loyaltyadvantages' })
      }
      this.isLoading = false
    }
  },
  computed: {}
}
</script>
